/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import Typography from "@mui/material/Typography/Typography";
import { Button } from "@mui/material";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { PathParams } from "../../types/PropsTypes";
import {
  DataFarmerListResponseType,
  DataFarmsResponseType,
  PaginationWithSort,
  ShareRequestsResponseType,
} from "../../types/DataTypes";
import ListRecordingSitesCard from "../listRecordingSitePage/ListRecordingSitesCard";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import { userRole } from "../../constants/userRoleConstants";
import BasicFarmInformation from "./BasicFarmInformation";
import FarmAdminsList from "./FarmAdminsList";
import AdditionalFarmInformation from "./AdditionalFarmInformation";
import areAllPropertiesEmpty from "../../utils/isObjectEmpty";

function FarmProfilePage() {
  const { id } = useParams<PathParams>();
  const { token, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const page = location.state?.page;
  const url = id
    ? `${REACT_APP_API_URL}/farms/${id}`
    : `${REACT_APP_API_URL}/farms/my-farm`;
  const { responseData, loading, error } = useFetch<DataFarmsResponseType>({
    url,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  const { responseData: responseDataListFarmers } = useFetch<
    DataFarmerListResponseType[]
  >({
    url: `${REACT_APP_API_URL}/farms/${responseData?.id}/users`,
    method: "GET",
    headers: requestHeaderToken(token),
    enabled: Boolean(user) && Boolean(responseData?.id),
  });

  const { name, additional_data: additionalData } = responseData || {};

  const handleNavigateRequests = (id: string | undefined) => {
    navigate(`/farms/${responseData?.id}/share-requests`);
  };

  const [fundingOrganizations, setFundingOrganizations] = useState<string[]>(
    []
  );
  const { responseData: shareRequestData } = useFetch<
    PaginationWithSort<ShareRequestsResponseType>
  >({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/share-requests/funding-requests/${id}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  useEffect(() => {
    if (shareRequestData) {
      const organizations = shareRequestData.results.map(
        (request) => request.organization.name
      );
      setFundingOrganizations(organizations);
    }
  }, [shareRequestData]);
  return (
    <Container>
      {user?.role !== userRole.farm_admin && <BackComponent />}
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode}
            {error?.error}
            {error?.message}
          </Typography>
        </Box>
      )}
      <Grid container spacing={2} direction="row" justifyContent="center">
        {!responseData && loading && (
          <Box display="flex" justifyContent="center" mt={20}>
            <CircularProgress />
          </Box>
        )}
        {responseData && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Card elevation={3}>
                <Box textAlign="center">
                  <Box sx={{ color: "red" }}>
                    {areAllPropertiesEmpty(additionalData) &&
                    user?.role === "FARM_ADMIN" ? (
                      <p>
                        Please enter additional data to see benchmark report
                      </p>
                    ) : null}
                  </Box>
                  <Box display="flex">
                    <Box
                      flexGrow={2}
                      pl={
                        user?.role &&
                        {
                          [userRole.admin]: 5,
                        }[user.role]
                      }
                    >
                      <CardHeader title={name} />
                    </Box>
                  </Box>
                </Box>
              </Card>
              <BasicFarmInformation
                data={responseData}
                fundingOrganizations={fundingOrganizations}
              />
              <FarmAdminsList farmAdminList={responseDataListFarmers} />
              <AdditionalFarmInformation
                id={id || responseData.id}
                additionalData={additionalData}
              />
            </Grid>
            {user?.role !== userRole.organization_admin && (
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Button
                  type="button"
                  size="medium"
                  variant="contained"
                  fullWidth
                  onClick={() => handleNavigateRequests(responseData.id)}
                  sx={{ mb: 2 }}
                >
                  View Share Requests
                </Button>
                <ListRecordingSitesCard farmId={responseData.id} />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
}

export default FarmProfilePage;
