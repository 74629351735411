import { ChangeEvent, useEffect, useState } from "react";

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell/TableCell";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableBody from "@mui/material/TableBody/TableBody";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography/Typography";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import TableSortLabel from "@mui/material/TableSortLabel/TableSortLabel";
import Button from "@mui/material/Button/Button";
import { Checkbox, Tooltip } from "@mui/material";
import {
  BenchmarkGroupDataResponseType,
  DataFarmsResponseType,
  FarmsInBGDataType,
  Order,
  PaginationWithSort,
} from "../../types/DataTypes";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import useFetch from "../../service/useFetch";
import { useAuth } from "../../context/AuthContext";
import useDebounce from "../../hooks/useDebounce";
import { TABLE_HEADERS_FARMS } from "../../constants/tableConstants";
import TableCellWithDiv from "../../components/tableCell/TableCellWithDiv";
import "../../styles/tableCell.css";
import { SELECT_ITEMS } from "../../constants/selectItemConstants";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import { userRole } from "../../constants/userRoleConstants";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import InputAutoComplete from "../../components/input/InputAutoComplete";
import {
  BenchmarkgroupSelectionObject,
  OrganizationSelectionObject,
} from "../../types/PropsTypes";
import { OrderEnum } from "../../constants/enums";
import { highlightText } from "../../utils/highlightText";
import AddMoreFarmsModal from "../../components/modal/AddMoreFarmsModal";

function FarmListPage() {
  const { benchmarkGroupId } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [county, setCounty] = useState<string>("");
  const [postCode, setPostCode] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [benchmarkGroup, setBenchmarkGroup] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useState<string>("");
  const [debouncedValuePostCode, setDebouncedValuePostCode] =
    useState<string>("");
  const [order, setOrder] = useState<Order>(OrderEnum.ASC);
  const [sort, setSort] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams({ page: "0" });
  const { token, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [fromBenchmarkGroups, setFromBenchmarkGroups] = useState(false);
  const [selectedFarms, setSelectedFarms] = useState<string[]>([]);
  useEffect(() => {
    if (
      location.pathname.startsWith("/benchmark-groups/") &&
      location.pathname.endsWith("/farms/add-farms")
    ) {
      setFromBenchmarkGroups(true);
    } else if (location.pathname === "/farms") {
      setFromBenchmarkGroups(false);
    }
  }, [location]);

  const { responseData: allFarmsInBG } = useFetch<
    PaginationWithSort<FarmsInBGDataType>
  >({
    url: `${REACT_APP_API_URL}/benchmark-groups/${benchmarkGroupId}/farms?page=0&limit=10000`,
    method: "GET",
    headers: requestHeaderToken(token),
    enabled: Boolean(benchmarkGroupId),
  });

  useEffect(() => {
    if (allFarmsInBG) {
      setSelectedFarms(allFarmsInBG?.results.map((farm) => farm.id));
    }
  }, [allFarmsInBG]);

  const { responseData: responseDataOrganization } = useFetch<
    OrganizationSelectionObject[]
  >({
    url: `${REACT_APP_API_URL}/organization/role-filtered`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const { responseData: responseDataBenchmarkGroups } = useFetch<
    BenchmarkgroupSelectionObject[]
  >({
    url: `${REACT_APP_API_URL}/benchmark-groups/role-filtered`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  const { responseData, loading, error } = useFetch<
    PaginationWithSort<DataFarmsResponseType>
  >({
    url: `${REACT_APP_API_URL}/farms?page=${
      searchParams.get("page")
      // eslint-disable-next-line max-len
    }&limit=${rowsPerPage}&sort_by=${sort}&order=${order}&county=${county}&name=${search}&post_code=${postCode}&organization=${organization}&benchmark_group=${benchmarkGroup}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const { responseData: farmsInOrganization } = useFetch<
    PaginationWithSort<FarmsInBGDataType>
  >({
    url: `${REACT_APP_API_URL}/benchmark-groups/${benchmarkGroupId}/farms-in-organization?page=${
      searchParams.get("page")
      // eslint-disable-next-line max-len
    }&limit=${rowsPerPage}&sort_by=${sort}&order=${order}&county=${county}&name=${search}&post_code=${postCode}`,
    method: "GET",
    headers: requestHeaderToken(token),
    enabled: Boolean(benchmarkGroupId),
  });

  const { responseData: allFarmsInOrganization } = useFetch<
    PaginationWithSort<FarmsInBGDataType>
  >({
    url: `${REACT_APP_API_URL}/benchmark-groups/${benchmarkGroupId}/farms-in-organization?page=0&limit=100000`,
    method: "GET",
    headers: requestHeaderToken(token),
    enabled: Boolean(benchmarkGroupId),
  });

  useDebounce(() => {
    setSearch(debouncedValue);
  }, debouncedValue);

  const handleNavigate = () => {
    navigate("/farms/create");
  };
  const handleSearch = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDebouncedValue(event.target.value);
    setSearchParams({ page: "0" });
  };

  useDebounce(() => {
    setPostCode(debouncedValuePostCode);
  }, debouncedValuePostCode);

  const handleSearchPostCode = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDebouncedValuePostCode(event.target.value);
    setSearchParams({ page: "0" });
  };

  const handleChange = (event: any, newValue: any) => {
    setCounty(newValue?.value ?? "");
    setSearchParams({ page: "0" });
  };
  const handleChangeOrganization = (event: any, newValue: any) => {
    setOrganization(newValue?.id ?? "");
    setSearchParams({ page: "0" });
  };
  const handleChangeBenchmarkGroup = (event: any, newValue: any) => {
    setBenchmarkGroup(newValue?.id ?? "");
    setSearchParams({ page: "0" });
  };
  const handleSort = (sortName: string) => {
    setSort(sortName);
    setOrder(order === OrderEnum.ASC ? OrderEnum.DESC : OrderEnum.ASC);
  };

  const handleNavigateToForm = (id: string) => {
    navigate(`/farms/${id}`, { state: { page: searchParams.get("page") } });
  };

  const handleSelectFarm = (farmId: string) => {
    setSelectedFarms((prevSelected) => {
      if (prevSelected.includes(farmId)) {
        return prevSelected.filter((id) => id !== farmId);
      }
      return [...prevSelected, farmId];
    });
  };

  const handleSelectAllFarms = () => {
    if (selectedFarms.length === allFarmsInOrganization?.metadata.count) {
      setSelectedFarms([]);
    } else {
      const allFarmIds = allFarmsInOrganization
        ? allFarmsInOrganization?.results.map((farm) => farm.id)
        : [];
      setSelectedFarms(allFarmIds);
    }
  };
  const farms: DataFarmsResponseType[] = fromBenchmarkGroups
    ? (farmsInOrganization?.results as DataFarmsResponseType[])
    : responseData?.results || [];

  return (
    <Container>
      {fromBenchmarkGroups && <BackComponent />}
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <BackComponent />
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error} {"  "}
            {error?.message}
          </Typography>
        </Box>
      )}
      <Grid container spacing={0} direction="row" margin={0}>
        {user?.role &&
          {
            [userRole.admin]: (
              <>
                <Grid item container pb={2} xs={12} md={12} spacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <OutlinedInput
                      id="search"
                      name="search"
                      placeholder="Filter farms by name..."
                      type="text"
                      size="small"
                      onChange={(event) => handleSearch(event)}
                      value={debouncedValue}
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <OutlinedInput
                      id="search"
                      name="search"
                      placeholder="Filter farms by post code..."
                      type="text"
                      size="small"
                      onChange={(event) => handleSearchPostCode(event)}
                      value={debouncedValuePostCode}
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Button
                      fullWidth
                      type="button"
                      size="medium"
                      variant="contained"
                      onClick={handleNavigate}
                    >
                      Add farm
                    </Button>
                  </Grid>
                </Grid>
                <Grid item container pb={2} xs={12} md={12} spacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputAutoComplete
                      value={null}
                      label="County"
                      onChange={handleChange}
                      options={SELECT_ITEMS}
                      getOptionLabel={(option) => (option as any).label ?? ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputAutoComplete
                      onChange={handleChangeOrganization}
                      value={null}
                      label="Organization"
                      options={responseDataOrganization ?? []}
                      getOptionLabel={(organization) =>
                        (organization as OrganizationSelectionObject).name ?? ""
                      }
                      disabled={fromBenchmarkGroups}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputAutoComplete
                      onChange={handleChangeBenchmarkGroup}
                      value={null}
                      label="Benchmark group"
                      options={responseDataBenchmarkGroups ?? []}
                      getOptionLabel={(benchmarkGroup: any) =>
                        (benchmarkGroup as BenchmarkgroupSelectionObject)
                          .name ?? ""
                      }
                      disabled={fromBenchmarkGroups}
                    />
                  </Grid>
                </Grid>
                {fromBenchmarkGroups && (
                  <Grid item container pb={2} xs={12} md={12} spacing={1}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <AddMoreFarmsModal
                        bgId={benchmarkGroupId || ""}
                        farms={selectedFarms}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            ),
          }[user.role]}
        <Grid item xs={12} md={12} alignItems="center" justifyContent="center">
          <TableContainer component={Paper}>
            <Box sx={{ overflow: "auto" }}>
              <Box
                sx={{ width: "100%", display: "table", tableLayout: "fixed" }}
              >
                <Table aria-label="table">
                  <TableHead>
                    <TableRow>
                      {benchmarkGroupId && (
                        <TableCell
                          align="center"
                          sx={{ width: "50px" }}
                          onClick={handleSelectAllFarms}
                        >
                          <Tooltip title="Select All" arrow>
                            <span style={{ cursor: "pointer" }}>Select</span>
                          </Tooltip>
                        </TableCell>
                      )}
                      {TABLE_HEADERS_FARMS.map(({ sortName, label }) => (
                        <TableCell
                          className="tableCell"
                          key={label}
                          onClick={() => handleSort(sortName)}
                          sx={{ cursor: "pointer" }}
                          align="center"
                        >
                          {label}
                          {sortName && (
                            <TableSortLabel
                              active={sort === sortName}
                              direction={sort === sortName ? order : "asc"}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading && !farms && (
                      <TableRow>
                        <TableCell colSpan={10} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {farms?.map((data: DataFarmsResponseType) => {
                      return (
                        <TableRow
                          hover
                          sx={{
                            opacity: !data.is_active ? "0.38" : "none",
                            cursor: "pointer",
                            "&.MuiTableRow-root:hover": {
                              backgroundColor: "rgba(206, 133, 20, 0.12)",
                            },
                          }}
                          key={data.id}
                        >
                          {fromBenchmarkGroups && (
                            <TableCell>
                              <Checkbox
                                checked={selectedFarms.includes(data.id)}
                                onChange={() => handleSelectFarm(data.id)}
                              />
                            </TableCell>
                          )}
                          <TableCellWithDiv
                            onClick={() => handleNavigateToForm(data.id)}
                            content={highlightText(data.name, debouncedValue)}
                          />
                          <TableCellWithDiv
                            content={data.address}
                            onClick={() => handleNavigateToForm(data.id)}
                          />
                          <TableCellWithDiv
                            content={data.county}
                            onClick={() => handleNavigateToForm(data.id)}
                          />
                          <TableCellWithDiv
                            onClick={() => handleNavigateToForm(data.id)}
                            content={highlightText(
                              data.post_code,
                              debouncedValuePostCode
                            )}
                          />
                          <TableCellWithDiv
                            content={data.CPH_number}
                            onClick={() => handleNavigateToForm(data.id)}
                          />
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <PaginationComponent
                    path={
                      fromBenchmarkGroups
                        ? `/benchmark-groups/${benchmarkGroupId}/farms/add-farms`
                        : "/farms"
                    }
                    count={
                      fromBenchmarkGroups
                        ? allFarmsInOrganization?.metadata.count
                        : responseData?.metadata.count
                    }
                    page={
                      +(searchParams.get("page") as unknown as number) as number
                    }
                    rowsPerPage={rowsPerPage}
                    setPage={setSearchParams as unknown as (e: number) => void}
                    setRowsPerPage={setRowsPerPage}
                  />
                </Table>
              </Box>
            </Box>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

export default FarmListPage;
