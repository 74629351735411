import React from "react";

import { Link } from "react-router-dom";
import { useParams } from "react-router";

import Container from "@mui/material/Container";
import WebSiteLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import CardContent from "@mui/material/CardContent/CardContent";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import EditIcon from "@mui/icons-material/Edit";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { PathParams } from "../../types/PropsTypes";
import { SpeciesDataResponseType } from "../../types/DataTypes";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import { handleNavigateWebsite } from "../../utils/handleNavigateWebsite";

function SpeciesProfilePage() {
  const { id } = useParams<PathParams>();
  const { token } = useAuth();

  const { responseData, loading, error } = useFetch<SpeciesDataResponseType>({
    url: `${REACT_APP_API_URL}/species/${id}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  return (
    <Container>
      <BackComponent />
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error} {"  "}
            {error?.message}
          </Typography>
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center" mt={20}>
          <CircularProgress />
        </Box>
      )}
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {responseData && (
          <Grid item xs={12} md={8} alignItems="center" justifyContent="center">
            <Card elevation={3}>
              <Box textAlign="center">
                <Box display="flex">
                  <Box flexGrow={2} pl={5}>
                    <CardHeader title={responseData.latin} />
                  </Box>
                  <Box p={2}>
                    <Link to={`/species/edit/${id}`}>
                      <EditIcon fontSize="medium" color="action" />
                    </Link>
                  </Box>
                </Box>
                <Divider />
                <CardContent>
                  <Box p={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="h6" align="right">
                          Latin name:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{ fontWeight: "normal" }}
                        >
                          {responseData.latin}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="h6" align="right">
                          English name:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{ fontWeight: "normal" }}
                        >
                          {responseData.english}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="h6" align="right">
                          International name:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{ fontWeight: "normal" }}
                        >
                          {responseData.international}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="h6" align="right">
                          Habitat:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{ fontWeight: "normal" }}
                        >
                          {responseData.habitat?.habitat ||
                            "No data available."}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="h6" align="right">
                          Trophics:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{ fontWeight: "normal" }}
                        >
                          {responseData.trophics.length > 0
                            ? responseData.trophics.map((t, index) => (
                                <div key={index}>
                                  {t.trophic}
                                  {index < responseData.trophics.length - 1 && (
                                    <br />
                                  )}{" "}
                                </div>
                              ))
                            : "No data available."}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="h6" align="right">
                          Trophic level:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{ fontWeight: "normal" }}
                        >
                          {responseData.trophic_level}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="h6" align="right">
                          Bio indicators:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{ fontWeight: "normal" }}
                        >
                          {responseData.bio_indicators.length > 0
                            ? responseData.bio_indicators.map((b, index) => (
                                <div key={index}>
                                  {b.bio_indicator}
                                  {index <
                                    responseData.bio_indicators.length - 1 && (
                                    <br />
                                  )}{" "}
                                </div>
                              ))
                            : "No data available."}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="h6" align="right">
                          Assemblages:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{ fontWeight: "normal" }}
                        >
                          {responseData.assemblages.length > 0
                            ? responseData.assemblages.map((a, index) => (
                                <div key={index}>
                                  {a.assemblage}
                                  {index <
                                    responseData.assemblages.length - 1 && (
                                    <br />
                                  )}{" "}
                                </div>
                              ))
                            : "No data available."}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="h6" align="right">
                          Food sources:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{ fontWeight: "normal" }}
                        >
                          {responseData.food_sources.length > 0
                            ? responseData.food_sources.map((fs, index) => (
                                <div key={index}>
                                  {fs.food_source}
                                  {index <
                                    responseData.food_sources.length - 1 && (
                                    <br />
                                  )}{" "}
                                </div>
                              ))
                            : "No data available."}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="h6" align="right">
                          Song URL:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          align="left"
                          style={{
                            fontWeight: "normal",
                            cursor: "pointer",
                            color: "blue",
                            textDecoration: "underline",
                          }}
                        >
                          <WebSiteLink
                            onClick={() =>
                              handleNavigateWebsite(responseData.song_url)
                            }
                          >
                            {responseData.song_url}
                          </WebSiteLink>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default SpeciesProfilePage;
