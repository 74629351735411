import { Bar } from "react-chartjs-2";
import { useNavigate } from "react-router";
import { ActiveElement, ChartEvent, ChartOptions } from "chart.js";
import { theme } from "../../../styles/useTheme";
import { SpeciesChartProps } from "../../../types/PropsTypes";
import { SpeciesChartFarm } from "../../../types/DataTypes";

function SpeciesDiversityChart({ farms }: SpeciesChartProps) {
  const navigate = useNavigate();
  const colors = {
    diversity: {
      accessible: "#FFD96D",
      hover_accessible: "#E4AC58",
      access_limited: "#FFD685",
      unaccessible: "#FFE1A4",
      unaccessible_hover: theme.palette.primary.light,
    },
    red_species: {
      main: "#DA0505",
      hover: "#ae0404",
    },
  };
  const calculateColorForFarm = (farm: SpeciesChartFarm) => {
    const { has_access: hasAccess, has_limited_access: hasLimited } = farm;
    const {
      accessible,
      access_limited: accessLimited,
      unaccessible,
    } = colors.diversity;
    if (hasAccess) return accessible;
    if (hasLimited) return accessLimited;
    return unaccessible;
  };
  const data = {
    labels: farms?.map((farm: SpeciesChartFarm) => farm.name),
    datasets: [
      {
        label: "Red list species",
        data: farms?.map((farm) => farm.red_list),
        backgroundColor: colors.red_species.main,
        hoverBackgroundColor: colors.red_species.hover,
        borderColor: colors.red_species.main,
        categoryPercentage: 0.5,
        fill: false,
        tension: 0.1,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
      {
        label: "Diversity number",
        data: farms?.map((farm) => farm.diversity_number),
        backgroundColor: farms?.map((farm) => calculateColorForFarm(farm)),
        borderColor: theme.palette.primary.light,
        hoverBackgroundColor: farms?.map((farm) =>
          farm.has_access
            ? colors.diversity.hover_accessible
            : colors.diversity.unaccessible_hover
        ),
        fill: false,
        tension: 0.1,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    onClick: (event: ChartEvent, elements: ActiveElement[]) => {
      if (!elements.length) return;
      const { id: farmId, has_access: hasAccess } =
        farms?.[elements[0].index] || {};
      if (!hasAccess) return;
      navigate(`/farms/${farmId}`);
    },
    plugins: {
      legend: {
        display: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          minRotation: 90,
          font: {
            size: Math.min(12, 100 / (farms?.length || 1) + 7),
          },
        },
      },
    },
  };
  return <Bar data={data} options={options} />;
}

export default SpeciesDiversityChart;
