/* eslint-disable no-underscore-dangle */
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell/TableCell";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableBody from "@mui/material/TableBody/TableBody";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography/Typography";
import { OutlinedInput, InputAdornment, TableSortLabel } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import useFetch from "../../service/useFetch";
import { useAuth } from "../../context/AuthContext";
import {
  Order,
  PaginationWithSort,
  SpeciesDataResponseType,
} from "../../types/DataTypes";
import { TABLE_HEADERS_SPECIES_LIST } from "../../constants/tableConstants";
import useDebounce from "../../hooks/useDebounce";
import "../../styles/tableCell.css";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import TableCellWithDiv from "../../components/tableCell/TableCellWithDiv";
import { OrderEnum } from "../../constants/enums";
import isUserRoleNotAdmin from "../../utils/isUserRoleNotAdmin";
import StarsRating from "../../components/starsRating/StarsRating";
import { highlightText } from "../../utils/highlightText";

function SpeciesListPage() {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useState<string>("");
  const [order, setOrder] = useState<Order>(OrderEnum.ASC);
  const [sort, setSort] = useState<string>("");
  const { token, user } = useAuth();
  const navigate = useNavigate();

  const { responseData, loading, error } = useFetch<
    PaginationWithSort<SpeciesDataResponseType>
  >({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/species?page=${page}&limit=${rowsPerPage}&sort_by=${sort}&order=${order}&search=${search}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  useDebounce(() => {
    setSearch(debouncedValue);
  }, debouncedValue);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setDebouncedValue(event.target.value);
    setPage(0);
  };

  const handleSort = (sortName: string) => {
    setSort(sortName);
    setOrder(order === OrderEnum.ASC ? OrderEnum.DESC : OrderEnum.ASC);
  };

  const handleNavigateToSpeciesProfile = (id: string) => {
    navigate(`/species/${id}`);
  };

  return (
    <Container>
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <BackComponent />
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error}
            {error?.message}
          </Typography>
        </Box>
      )}
      <Grid container spacing={0} direction="column">
        <Grid container>
          <Grid item pb={2} xs={12} md={4} display="flex" gap={1}>
            <OutlinedInput
              fullWidth
              id="search"
              name="search"
              placeholder="Search species..."
              type="text"
              size="small"
              onChange={handleSearch}
              value={debouncedValue}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} alignItems="center" justifyContent="center">
          <TableContainer component={Paper}>
            <Box
              sx={{
                overflow: "auto",
                width: "100%",
                display: "table",
                tableLayout: "fixed",
              }}
            >
              <Table sx={{ minWidth: 600 }} aria-label="table">
                <TableHead>
                  <TableRow>
                    {TABLE_HEADERS_SPECIES_LIST.map(({ sortName, label }) => (
                      <TableCell
                        className="tableCell"
                        key={label}
                        onClick={() => handleSort(sortName)}
                        sx={{ cursor: "pointer" }}
                        align="center"
                      >
                        {label}
                        {sortName && (
                          <TableSortLabel
                            active={sort === sortName}
                            direction={sort === sortName ? order : "asc"}
                          />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && !responseData && (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {responseData?.results.map(
                    (data: SpeciesDataResponseType) => (
                      <TableRow
                        key={data.latin}
                        onClick={() =>
                          isUserRoleNotAdmin(user)
                            ? null
                            : handleNavigateToSpeciesProfile(data._id)
                        }
                        sx={{
                          cursor: "pointer",
                          "&.MuiTableRow-root:hover": {
                            backgroundColor: "rgba(206, 133, 20, 0.12)",
                          },
                        }}
                      >
                        <TableCellWithDiv
                          width="auto"
                          content={highlightText(data.latin, search)}
                        />
                        <TableCellWithDiv
                          width="auto"
                          content={highlightText(data.english, search)}
                        />
                        <TableCellWithDiv
                          width="auto"
                          content={data.habitat?.habitat}
                        />
                        <TableCellWithDiv
                          width="auto"
                          content={
                            <div>
                              {data.trophics.map((d, index) => (
                                <div key={index}>{d.trophic}</div>
                              ))}
                            </div>
                          }
                        />
                        <TableCellWithDiv
                          width="auto"
                          content={data.trophic_level}
                        />
                        <TableCellWithDiv
                          width="auto"
                          content={
                            <div>
                              {data.bio_indicators.map((d, index) => (
                                <div key={index}>{d.bio_indicator}</div>
                              ))}
                            </div>
                          }
                        />
                        <TableCellWithDiv
                          width="auto"
                          content={
                            <div>
                              {data.assemblages.map((d, index) => (
                                <div key={index}>{d.assemblage}</div>
                              ))}
                            </div>
                          }
                        />
                        <TableCellWithDiv
                          width="auto"
                          content={
                            <div>
                              {data.food_sources.map((d, index) => (
                                <div key={index}>{d.food_source}</div>
                              ))}
                            </div>
                          }
                        />
                        <TableCellWithDiv
                          width="auto"
                          content={<StarsRating rating={data.rating} />}
                        />
                      </TableRow>
                    )
                  )}
                </TableBody>
                <PaginationComponent
                  count={responseData?.metadata.count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </Table>
            </Box>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}
export default SpeciesListPage;
