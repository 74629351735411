/* eslint-disable no-unused-vars */
export enum ShareRequestStatus {
  PENDING = "PENDING",
  REVOKED = "REVOKED",
  APPROVED = "APPROVED",
  APPROVED_LIMITED = "APPROVED_LIMITED",
  REJECTED = "REJECTED",
  ALL = "",
  FUNDING = "FUNDING",
}

export enum OrderEnum {
  ASC = "asc",
  DESC = "desc",
}

export enum TrophicEnum {
  CARNIVORE = "CARNIVORE",
  HERBIVORE = "HERBIVORE",
  OMNIVORE = "OMNIVORE",
  SCAVENGER = "SCAVENGER",
}
