import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Link } from "react-router-dom";
import FetchComponent from "../../components/fetchComponent/FetchComponent";
import { DataFarmsResponseType } from "../../types/DataTypes";
import { useAuth } from "../../context/AuthContext";
import { userRole } from "../../constants/userRoleConstants";

export default function BasicFarmInformation({
  data,
  fundingOrganizations,
}: {
  data: DataFarmsResponseType;
  fundingOrganizations: string[];
}) {
  const { user } = useAuth();
  const {
    id,
    CPH_number: CPHNumber,
    SBI_number: SBINumber,
    address,
    county,
    post_code: postCode,
    benchmark_group: benchmarkGroup,
    main_interest: mainInterest,
  } = data;
  const formattedMainInterest = mainInterest
    ? (mainInterest.charAt(0).toUpperCase() + mainInterest.slice(1)).replaceAll(
        "-",
        " "
      )
    : "";
  return (
    <Box mt={3}>
      <Accordion elevation={3} defaultExpanded disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ minHeight: 0 }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <Typography variant="h6" textAlign="center" flexGrow={1}>
              Basic information
            </Typography>
            {user?.role !== userRole.organization_admin && (
              <Box alignSelf="flex-end">
                <Link to={`/farms/edit/basic-info/${id}`}>
                  <EditIcon fontSize="small" color="action" />
                </Link>
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box p={2}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography variant="body1" align="right">
                  County:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body1" align="left">
                  {county}
                </Typography>
              </Grid>
              {user?.role &&
                {
                  [userRole.admin]: (
                    <>
                      <Grid item xs={5}>
                        <Typography variant="body1" align="right">
                          Benchmark groups:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body1" align="left">
                          <FetchComponent
                            id={benchmarkGroup}
                            path="benchmark-groups"
                          />
                        </Typography>
                      </Grid>
                    </>
                  ),
                }[user.role]}
              <Grid item xs={5}>
                <Typography variant="body1" align="right">
                  Address:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body1" align="left">
                  {address}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body1" align="right">
                  Post code:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body1" align="left">
                  {postCode}
                </Typography>
              </Grid>
              {CPHNumber ? (
                <>
                  <Grid item xs={5}>
                    <Typography variant="body1" align="right">
                      CPH number:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body1" align="left">
                      {CPHNumber}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {SBINumber ? (
                <>
                  <Grid item xs={5}>
                    <Typography variant="body1" align="right">
                      SBI number:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body1" align="left">
                      {SBINumber}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {formattedMainInterest ? (
                <>
                  <Grid item xs={5}>
                    <Typography variant="body1" align="right">
                      Main interest:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body1" align="left">
                      {formattedMainInterest}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {fundingOrganizations && fundingOrganizations.length > 0 ? (
                <>
                  <Grid item xs={5}>
                    <Typography variant="body1" align="right">
                      Funding organizations:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body1" align="left">
                      {fundingOrganizations.join(", ")}
                    </Typography>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
