import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";

import {
  AdditionalData,
  ShareData,
  WaterKeys,
  LandUsage,
} from "../../types/DataTypes";
import areAllPropertiesEmpty from "../../utils/isObjectEmpty";
import { useAuth } from "../../context/AuthContext";
import { userRole } from "../../constants/userRoleConstants";

export default function AdditionalFarmInformation({
  id,
  additionalData,
}: {
  id: string;
  additionalData: AdditionalData | undefined;
}) {
  const {
    farm_size: farmSize,
    sustainability_systems: sustainabilitySystems,
    retail_suppliers: retailSuppliers,
    dairy_suppliers: dairySuppliers,
    meat_suppliers: meatSuppliers,
    beverage_suppliers: beverageSuppliers,
    livestock_stocking_density: livestockDensity,
    natural_water: naturalWater,
    land_usage: landUsage,
  } = additionalData || {};
  const strToUpperCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const { user } = useAuth();
  return (
    <Box mt={3}>
      <Accordion elevation={3} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ minHeight: 0 }}
        >
          <Box display="flex" flexDirection="row" gap={2}>
            <Typography variant="h6" flexGrow={2}>
              Additional information
            </Typography>
            {user?.role !== userRole.organization_admin && (
              <Box alignSelf="flex-end">
                <Link to={`/farms/edit/additional-info/${id}`}>
                  <EditIcon fontSize="small" color="action" />
                </Link>
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {areAllPropertiesEmpty(additionalData) ? (
            <Typography variant="body1" align="center">
              No additional information for this farm
            </Typography>
          ) : (
            <Box p={2} display="flex" justifyContent="space-between">
              <Grid container spacing={1}>
                {farmSize ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="right">
                        Farm size:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="left">
                        {farmSize} ha
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {sustainabilitySystems && sustainabilitySystems.length ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="right">
                        Sustainability Systems:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="left">
                        {sustainabilitySystems.join(", ")}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {retailSuppliers && retailSuppliers.length ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="right">
                        Retail supplier to:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="left">
                        {retailSuppliers.join(", ")}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {dairySuppliers && dairySuppliers.length ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="right">
                        Dairy supplier to:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="left">
                        {dairySuppliers.join(", ")}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {meatSuppliers && meatSuppliers.length ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="right">
                        Meat supplier to:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="left">
                        {meatSuppliers.join(", ")}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {beverageSuppliers && beverageSuppliers.length ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="right">
                        Beverage supplier to:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="left">
                        {beverageSuppliers.join(", ")}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {livestockDensity && Object.keys(livestockDensity).length ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="right">
                        Livestock density: <br />
                        <span style={{ fontSize: "0.7em" }}>
                          (per hectare compared to the average farm)
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="left">
                        {Object.keys(livestockDensity).map((key) => (
                          <Grid
                            container
                            spacing={2}
                            key={key}
                            alignItems="self-end"
                          >
                            <Grid item xs={5}>
                              <Typography variant="body1" align="left">
                                {key}:
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body1" align="left">
                                {livestockDensity[key]}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {naturalWater && Object.keys(naturalWater).length ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="right">
                        Natural water:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="left">
                        {Object.keys(naturalWater).map((key) => (
                          <Grid
                            container
                            spacing={2}
                            key={key}
                            alignItems="self-end"
                          >
                            <Grid item xs={5}>
                              <Typography variant="body1" align="left">
                                {strToUpperCase(key as WaterKeys)}:
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body1" align="left">
                                {naturalWater[key as WaterKeys] ? "yes" : "no"}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {landUsage && Object.keys(landUsage).length ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="right">
                        Land usage:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="left">
                        {Object.keys(landUsage).map((key) => (
                          <Grid
                            container
                            spacing={2}
                            key={key}
                            alignItems="self-end"
                          >
                            <Grid item xs={8}>
                              <Typography variant="body1" align="left">
                                {strToUpperCase(key.split("_").join(" "))}:
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body1" align="left">
                                {landUsage[key as keyof LandUsage]}
                                {key !== "other" ? "%" : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
