import React from "react";

import { Link } from "react-router-dom";
import { useParams } from "react-router";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import CardContent from "@mui/material/CardContent/CardContent";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import EditIcon from "@mui/icons-material/Edit";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { PathParams } from "../../types/PropsTypes";
import { DataResponseType } from "../../types/DataTypes";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import FetchComponent from "../../components/fetchComponent/FetchComponent";
import { getRoleName } from "../../utils/getRoleName";

function UserProfilePage() {
  const { id } = useParams<PathParams>();
  const { token } = useAuth();

  const { responseData, loading, error } = useFetch<DataResponseType>({
    url: `${REACT_APP_API_URL}/users/${id}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  return (
    <Container>
      <BackComponent />
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error} {"  "}
            {error?.message}
          </Typography>
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center" mt={20}>
          <CircularProgress />
        </Box>
      )}
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {responseData && (
          <Grid item xs={12} md={8} alignItems="center" justifyContent="center">
            <Card elevation={3}>
              <Box textAlign="center">
                <Box display="flex">
                  <Box flexGrow={2} pl={5}>
                    <CardHeader title={responseData.name} />
                  </Box>
                  <Box p={2}>
                    <Link to={`/users/edit/${id}`}>
                      <EditIcon fontSize="medium" color="action" />
                    </Link>
                  </Box>
                </Box>
                <Divider />
                <CardContent>
                  <Box p={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <Typography align="right" variant="h6">
                          Email:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography align="left" variant="h6">
                          {responseData.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography align="right" variant="h6">
                          Role:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography align="left" variant="h6">
                          {getRoleName(responseData.role)}
                        </Typography>
                      </Grid>

                      {responseData.farm ? (
                        <>
                          <Grid item xs={5}>
                            <Typography align="right" variant="h6">
                              Farm:
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography
                              align="left"
                              variant="h6"
                              style={{ fontWeight: "normal" }}
                            >
                              <FetchComponent
                                id={responseData.farm}
                                path="farms"
                              />
                            </Typography>
                          </Grid>
                        </>
                      ) : null}
                      {responseData.organization ? (
                        <>
                          <Grid item xs={6}>
                            <Typography align="right" variant="h6">
                              Organization:
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography align="left" variant="h6">
                              <FetchComponent
                                id={responseData.organization}
                                path="organization"
                              />
                            </Typography>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </Box>
                  <Box display="flex" justifyContent="end">
                    {!responseData.is_active ? (
                      <Card elevation={3}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          gap={1}
                          p={2}
                        >
                          <Typography variant="body1" color="error">
                            Deactivated user{" "}
                          </Typography>
                          <PersonOffIcon color="error" />
                        </Box>
                      </Card>
                    ) : null}
                  </Box>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default UserProfilePage;
