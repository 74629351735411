import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  InputLabel,
} from "@mui/material";
import BenchmarkFilter from "./BenchmarkFilter";
import SpeciesDiversityChart from "../../../components/cards/SpeciesDiversityCard/SpeciesDiversityChart";
import { useAuth } from "../../../context/AuthContext";
import useFetch from "../../../service/useFetch";
import { REACT_APP_API_URL } from "../../../constants/apiConstants";
import { requestHeaderToken } from "../../../utils/requestHeaderToken";
import { SpeciesChartFarm } from "../../../types/DataTypes";
import YearsDropdown from "../../../components/yearDropdown/YearsDropdown";
import AdditionalSpeciesData from "../../../components/cards/SpeciesDiversityCard/AdditionalSpeciesData";

export default function BarChartCard() {
  const { user, token } = useAuth();
  const [benchGroupId, setBenchGroupId] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const handleChange = (arg: string) => {
    setBenchGroupId(arg);
  };
  const [showAll, setShowAll] = useState<boolean>(true);
  const { responseData: data, error } = useFetch<SpeciesChartFarm[]>({
    url: `${REACT_APP_API_URL}/benchmark-groups/${benchGroupId}/species-diversity?year=${selectedYear}&showAll=${showAll}`,
    method: "GET",
    headers: requestHeaderToken(token),
    enabled: Boolean(benchGroupId),
  });
  const content = () => {
    if (error) {
      return <div>Something went wrong!</div>;
    }
    if (!data) {
      return <div>Please select benchmark group</div>;
    }
    if (data && data.length !== 0) {
      return (
        <>
          {user?.role === "ORGANIZATION_ADMIN" && (
            <AdditionalSpeciesData benchmarkGroupId={benchGroupId} />
          )}
          <SpeciesDiversityChart farms={data} />
        </>
      );
    }
    return <div>No data for that benchmark group</div>;
  };

  const handleShowAllChange = () => {
    setShowAll(!showAll);
  };

  return (
    <Card elevation={3}>
      <Box sx={{ textAlign: "center", padding: 2.5 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
            gap: 2,
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <CardHeader title="Species diversity" />
          <BenchmarkFilter value={benchGroupId} onChange={handleChange} />
          <YearsDropdown
            onChange={setSelectedYear}
            selectedYear={selectedYear}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="check"
                name="show_all"
                onChange={handleShowAllChange}
                checked={!showAll}
              />
            }
            label="Hide farms with no data"
          />
        </Box>
        <CardContent>{content()}</CardContent>
      </Box>
    </Card>
  );
}
