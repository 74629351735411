import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataFarmerListResponseType } from "../../types/DataTypes";
import areAllPropertiesEmpty from "../../utils/isObjectEmpty";

export default function FarmAdminsList({
  farmAdminList,
}: {
  farmAdminList: DataFarmerListResponseType[] | undefined;
}) {
  return (
    <Box mt={3}>
      <Accordion elevation={3} defaultExpanded disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ minHeight: 0 }}
        >
          <Typography variant="h6" align="center">
            Farm admin(s)
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {areAllPropertiesEmpty(farmAdminList) ? (
            <Typography variant="body1" align="center">
              Currently no data about admins
            </Typography>
          ) : (
            farmAdminList?.map((farmer: DataFarmerListResponseType) => {
              return (
                <Box
                  p={1}
                  key={farmer.id}
                  sx={{
                    opacity: farmer.is_active ? "none" : "0.38",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography variant="body1" align="right">
                        Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="body1" align="left">
                        {farmer.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body1" align="right">
                        Email:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="body1" align="left">
                        {farmer.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              );
            })
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
