import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { useNavigate } from "react-router";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAuth } from "../../../context/AuthContext";
import useFetch from "../../../service/useFetch";
import { FarmSelectionObject } from "../../../types/PropsTypes";
import { REACT_APP_API_URL } from "../../../constants/apiConstants";
import { requestHeaderToken } from "../../../utils/requestHeaderToken";
import BackComponent from "../../../components/back/BackComponent";
import InputAutoComplete from "../../../components/input/InputAutoComplete";
import useMutate from "../../../service/useMutate";
import {
  Best14Days,
  SiteToSiteReportDataType,
  SpeciesComparisonReportDataType,
} from "../../../types/DataTypes";
import SpeciesComparisonBarChart from "./SpeciesComparisonBarChart";

dayjs.extend(utc);

function SiteToSiteReportPage() {
  const { token, user } = useAuth();
  const navigate = useNavigate();
  const [selectedFarm1, setSelectedFarm1] =
    useState<FarmSelectionObject | null>(null);
  const [selectedFarm2, setSelectedFarm2] =
    useState<FarmSelectionObject | null>(null);
  const [farm1, setFarm1] = useState<string>("");
  const [farm2, setFarm2] = useState<string>("");
  const [farms, setFarms] = useState<string[]>([]);
  const [fromDate, setFromDate] = React.useState<Dayjs>(
    dayjs(Date.now()).utc().startOf("year")
  );
  const [toDate, setToDate] = React.useState<Dayjs>(
    dayjs(Date.now()).utc().endOf("year")
  );
  const [highlitedDays, setHighlitedDays] = useState<string[]>([]);
  const [recordingSites, setRecordingSites] = useState<string[]>([]);
  const [recordingSite1, setRecordingSite1] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [recordingSite2, setRecordingSite2] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const accessType = user?.role === "ORGANIZATION_ADMIN" ? "limited" : "all";

  const [report, setReport] = useState<SpeciesComparisonReportDataType | null>(
    null
  );
  const [activeReportData, setActiveReportData] =
    useState<SiteToSiteReportDataType | null>(null);
  const { responseData, loading, error } = useFetch<FarmSelectionObject[]>({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/farms/role-filtered?access_type=${accessType}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  useEffect(() => {
    const savedState = sessionStorage.getItem("state");

    if (savedState) {
      const parsedState = JSON.parse(savedState);
      const {
        farm1,
        selectedFarm1,
        farm2,
        selectedFarm2,
        recordingSite1,
        recordingSite2,
        recordingSites,
        fromDate,
        toDate,
        report,
        highlitedDays,
      } = parsedState;

      setFarm1(farm1);
      setSelectedFarm1(selectedFarm1);
      setFarm2(farm2);
      setSelectedFarm2(selectedFarm2);
      setRecordingSite1(recordingSite1);
      setRecordingSite2(recordingSite2);
      setRecordingSites(recordingSites);
      setFromDate(dayjs.utc(fromDate));
      setToDate(dayjs.utc(toDate));
      setReport(report);
      setHighlitedDays(highlitedDays);
      sessionStorage.removeItem("state");
    }
  }, []);

  const { fetchData: dates } = useMutate({
    // eslint-disable-next-line max-len
    url: `${REACT_APP_API_URL}/farms/${farm1}/recording-sites/dates?recording_sites=${recordingSite1?.id}&recording_sites=${recordingSite2?.id}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });

  const handleFarmChange = (newValue: any, isFarm1: boolean) => {
    if (isFarm1) {
      setFarm1(newValue?.id ?? "");
      setRecordingSite1(null);
      setSelectedFarm1(newValue);
    } else {
      setFarm2(newValue?.id ?? "");
      setRecordingSite2(null);
      setSelectedFarm2(newValue);
    }
    if (newValue) farms.push(newValue.id);
    setRecordingSites([]);
    setFromDate(dayjs(Date.now()).utc().startOf("year"));
    setToDate(dayjs(Date.now()).utc().endOf("year"));
    setReport(null);
  };

  const { fetchData: fetchReport } = useMutate({
    url: `${REACT_APP_API_URL}/recording-sites/site-to-site-report`,
    method: "POST",
    headers: requestHeaderToken(token),
  });

  const farmLookup = useMemo(() => {
    if (responseData)
      return Object.fromEntries(responseData.map((farm) => [farm.id, farm]));
    return {};
  }, [responseData]);

  const handleRSChange = (
    newSite: { id: string; name: string } | null,
    isSite1: boolean
  ) => {
    setReport(null);
    if (isSite1) {
      setRecordingSite1(newSite);
    } else {
      setRecordingSite2(newSite);
    }
    if (newSite) recordingSites.push(newSite.id);
  };

  useEffect(() => {
    if (recordingSite1 != null && recordingSite2 != null) {
      dates({}).then((data) => setHighlitedDays(data));
    }
  }, [dates, recordingSites, setHighlitedDays]);

  const getDayElement = useCallback(
    (
      day: Dayjs,
      selectedDays: Dayjs[],
      pickersDayProps: PickersDayProps<Dayjs>
    ) => {
      const isUploaded = highlitedDays.includes(
        day.utc(true).toISOString().split("T")[0]
      );
      return <PickersDay {...pickersDayProps} selected={isUploaded} />;
    },
    [highlitedDays]
  );

  const formatDate = (date: Dayjs) => {
    const offset = date.utcOffset();
    const formattedDate = new Date(date.toISOString());
    formattedDate.setMinutes(formattedDate.getMinutes() + offset);
    return formattedDate.toISOString();
  };

  const onSubmit = () => {
    if (farm1 && recordingSite1 && farm2 && recordingSite2) {
      setActiveReportData({
        farm1,
        farm2,
        recording_sites: [recordingSite1.id, recordingSite2.id],
        from: formatDate(fromDate),
        to: formatDate(toDate),
        year: fromDate.get("year"),
      });
      setReport(null);
      setIsReportLoading(true);
    } else {
      setActiveReportData(null);
      setReport(null);
      setIsReportLoading(false);
    }
  };
  const fetchCallback = useCallback(async () => {
    if (activeReportData) {
      await fetchReport(activeReportData).then(setReport);
      setIsReportLoading(false);
    }
  }, [activeReportData, fetchReport]);

  useEffect(() => {
    fetchCallback();
  }, [fetchCallback]);

  const handleChartStateUpdate = (chartState: any) => {
    const combinedState = {
      farm1,
      selectedFarm1,
      farm2,
      selectedFarm2,
      recordingSite1,
      recordingSite2,
      recordingSites,
      fromDate,
      toDate,
      report,
      highlitedDays,
      ...chartState,
    };

    navigate("/species-list-card", { state: combinedState });
  };

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {error && (
          <Box display="flex" alignItems="center" flexDirection="column">
            <BackComponent />
            <Typography variant="h4" color="error">
              {error?.statusCode} {"  "}
              {error?.error}
            </Typography>
          </Box>
        )}
        <Grid container spacing={2} direction="row" margin={0}>
          <Grid item>
            {!loading && responseData && (
              <Grid spacing={2} container>
                <Grid item xs={12} md={6} lg={6}>
                  <InputAutoComplete
                    value={selectedFarm1}
                    label="Farm 1"
                    onChange={(e, value) => handleFarmChange(value, true)}
                    options={responseData ?? []}
                    getOptionLabel={(farm1) =>
                      (farm1 as FarmSelectionObject).name ?? ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Autocomplete
                    size="small"
                    id="site1_id"
                    value={farm1 ? recordingSite1 : null}
                    options={
                      farm1
                        ? (farmLookup[farm1]?.recording_sites || []).filter(
                            (site) => site.id !== recordingSite2?.id
                          )
                        : []
                    }
                    filterOptions={(option) => option}
                    getOptionLabel={(rs: any) => farm1 && `${rs.name}`}
                    onChange={(e, value) => handleRSChange(value, true)}
                    renderInput={(params) => (
                      <TextField {...params} label="Site 1" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <InputAutoComplete
                    value={selectedFarm2}
                    label="Farm 2"
                    onChange={(e, value) => handleFarmChange(value, false)}
                    options={responseData ?? []}
                    getOptionLabel={(farm) =>
                      (farm as FarmSelectionObject).name ?? ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Autocomplete
                    size="small"
                    id="site2_id"
                    value={farm2 ? recordingSite2 : null}
                    options={
                      farm2
                        ? (farmLookup[farm2]?.recording_sites || []).filter(
                            (site) => site.id !== recordingSite1?.id
                          )
                        : []
                    }
                    filterOptions={(option) => option}
                    getOptionLabel={(rs: any) => farm2 && `${rs.name}`}
                    onChange={(e, value) => handleRSChange(value, false)}
                    renderInput={(params) => (
                      <TextField {...params} label="Site 2" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <DatePicker
                    views={["year"]}
                    openTo="year"
                    label="Year"
                    value={fromDate}
                    onChange={(newValue) => {
                      if (newValue && newValue.isValid()) {
                        setFromDate(
                          fromDate.clone().set("year", newValue.get("year"))
                        );
                        setToDate(
                          toDate.clone().set("year", newValue.get("year"))
                        );
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        helperText={null}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <DatePicker
                    views={["day"]}
                    inputFormat="D MMM YYYY"
                    disableMaskedInput
                    label="From Date"
                    value={fromDate}
                    minDate={fromDate.startOf("year")}
                    maxDate={fromDate.endOf("year")}
                    onChange={(newValue) => {
                      if (newValue) {
                        setFromDate(newValue);
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        helperText={null}
                        fullWidth
                      />
                    )}
                    renderDay={getDayElement}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <DatePicker
                    views={["day"]}
                    inputFormat="D MMM YYYY"
                    disableMaskedInput
                    label="To Date"
                    value={toDate}
                    minDate={fromDate.startOf("year")}
                    maxDate={fromDate.endOf("year")}
                    onChange={(newValue) => {
                      if (newValue) {
                        setToDate(newValue);
                      }
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        helperText={null}
                        fullWidth
                      />
                    )}
                    renderDay={getDayElement}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={2}>
                  <Button
                    onClick={() => onSubmit()}
                    variant="contained"
                    sx={{ width: "100%" }}
                  >
                    Compare sites
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {(!farm1 || !farm2 || !recordingSite1 || !recordingSite2) && (
                    <Alert severity="info">
                      Please choose farms and recording sites.
                    </Alert>
                  )}
                  {isReportLoading ? (
                    <Box
                      sx={{
                        display: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    report &&
                    recordingSite1 &&
                    recordingSite2 && (
                      <SpeciesComparisonBarChart
                        reportData={report}
                        label1={recordingSite1?.name}
                        label2={recordingSite2?.name}
                        onStateUpdate={handleChartStateUpdate}
                      />
                    )
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Container>
  );
}

export default SiteToSiteReportPage;
