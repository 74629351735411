/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import { Link } from "react-router-dom";
import { useParams } from "react-router";
import dayjs from "dayjs";

import WebSiteLink from "@mui/material/Link";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import CardContent from "@mui/material/CardContent/CardContent";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import EditIcon from "@mui/icons-material/Edit";
import GroupsIcon from "@mui/icons-material/Groups";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { PathParams } from "../../types/PropsTypes";
import { OrganizationDataResponseType } from "../../types/DataTypes";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import { handleNavigateWebsite } from "../../utils/handleNavigateWebsite";
import RequestDataShareModal from "../../components/modal/RequestDataShareModal";
import { userRole } from "../../constants/userRoleConstants";
import ListShareRequestTable from "../listShareRequestPage/ListShareRequestsTable";

function OrganizationProfilePage() {
  const [refresh, setRefresh] = useState(Math.random());
  const { id } = useParams<PathParams>();
  const { token, user } = useAuth();
  const url = id
    ? `${REACT_APP_API_URL}/organization/${id}`
    : `${REACT_APP_API_URL}/organization/my-organization`;
  const { responseData, loading, error } =
    useFetch<OrganizationDataResponseType>({
      url,
      method: "GET",
      headers: requestHeaderToken(token),
    });
  const refreshRecordingSiteList = () => {
    setRefresh(Math.random());
  };

  return (
    <>
      {user?.role !== userRole.organization_admin && <BackComponent />}
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode}
            {error?.error}
            {error?.message}
          </Typography>
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center" mt={20}>
          <CircularProgress />
        </Box>
      )}
      {responseData && (
        <Grid
          container
          spacing={5}
          pl={{ sm: 0, xs: 0, md: 10 }}
          pr={{ sm: 0, xs: 0, md: 10 }}
        >
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Card elevation={3}>
              <Box textAlign="center" pb={!responseData.is_active ? 5 : 12}>
                <Box display="flex">
                  <Box
                    flexGrow={2}
                    pl={
                      user?.role &&
                      {
                        [userRole.admin]: 5,
                      }[user.role]
                    }
                  >
                    <CardHeader
                      sx={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        width: "100%",
                      }}
                      title={responseData.name}
                    />
                  </Box>
                  {user?.role &&
                    {
                      [userRole.admin]: (
                        <Box p={2}>
                          <Link to={`/organization/edit/${responseData.id}`}>
                            <EditIcon fontSize="medium" color="action" />
                          </Link>
                        </Box>
                      ),
                    }[user.role]}
                </Box>
                <Divider />
                <CardContent>
                  <Box textAlign="left" p={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <Typography variant="h6" align="right">
                          Website URL:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="h6" align="left">
                          <WebSiteLink
                            onClick={() =>
                              handleNavigateWebsite(responseData.website_url)
                            }
                          >
                            {responseData.website_url}
                          </WebSiteLink>
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="h6" align="right">
                          Date created:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="h6" align="left">
                          {dayjs(responseData.created_at).format("DD/MM/YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="h6" align="right">
                          Description:
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body1" align="left">
                          {responseData.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box display="flex" justifyContent="end">
                    {!responseData.is_active ? (
                      <Card elevation={3}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          gap={1}
                          p={2}
                        >
                          <Typography variant="body1" color="error">
                            Deactivated organization
                          </Typography>
                          <Box sx={{ position: "relative" }}>
                            <Typography
                              sx={{
                                position: "absolute",
                                right: 5,
                                bottom: -2,
                                transform: "rotate(-30deg)",
                              }}
                              variant="h4"
                              color="error"
                            >
                              \
                            </Typography>
                            <GroupsIcon color="error" />
                          </Box>
                        </Box>
                      </Card>
                    ) : null}
                  </Box>
                </CardContent>
              </Box>
            </Card>
          </Grid>
          <Grid item container spacing={2} xs={12} sm={12} md={12} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12} width="100%">
              <ListShareRequestTable path="organization" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <RequestDataShareModal
                refreshRecordingSiteList={refreshRecordingSiteList}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default OrganizationProfilePage;
