/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";

import WebSiteLink from "@mui/material/Link";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import Card from "@mui/material/Card/Card";
import CardHeader from "@mui/material/CardHeader/CardHeader";
import CardContent from "@mui/material/CardContent/CardContent";
import Typography from "@mui/material/Typography/Typography";
import Divider from "@mui/material/Divider/Divider";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button/Button";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import { useAuth } from "../../context/AuthContext";
import useFetch from "../../service/useFetch";
import { PathParams } from "../../types/PropsTypes";
import { BenchmarkGroupDataResponseType } from "../../types/DataTypes";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import { handleNavigateWebsite } from "../../utils/handleNavigateWebsite";
import BenchmarkGroupsIcon from "../../components/icon/BenchmarkGroupsIcon";
import FetchComponent from "../../components/fetchComponent/FetchComponent";
import { userRole } from "../../constants/userRoleConstants";
import SpeciesDiversityCard from "../../components/cards/SpeciesDiversityCard/SpeciesDiversityCard";

function BenchmarkGroupProfilePage() {
  const { id } = useParams<PathParams>();
  const { token, user } = useAuth();
  const navigate = useNavigate();

  const { responseData, loading, error } =
    useFetch<BenchmarkGroupDataResponseType>({
      url: `${REACT_APP_API_URL}/benchmark-groups/${id}`,
      method: "GET",
      headers: requestHeaderToken(token),
    });
  const handleNavigateFarms = () => {
    navigate(`/benchmark-groups/${id}/farms`);
  };
  const { is_default: isDefaultBG } = responseData || {};

  return (
    <Container>
      <BackComponent />
      {error && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h4" color="error">
            {error?.statusCode} {"  "}
            {error?.error} {"  "}
            {error?.message}
          </Typography>
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center" mt={20}>
          <CircularProgress />
        </Box>
      )}
      <Grid container spacing={0} justifyContent="center">
        {responseData && (
          <>
            {(user?.role === userRole.admin ||
              (user?.role === userRole.organization_admin && !isDefaultBG)) && (
              <Grid
                item
                xs={12}
                md={8}
                lg={10}
                pb={2}
                display="flex"
                justifyContent="end"
              >
                <Button
                  sx={{ width: "50%" }}
                  type="button"
                  size="medium"
                  variant="contained"
                  onClick={handleNavigateFarms}
                >
                  View farms
                </Button>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              lg={10}
              md={10}
              alignItems="center"
              justifyContent="center"
            >
              <Card elevation={3}>
                <Box textAlign="center">
                  <Box display="flex">
                    <Box
                      flexGrow={2}
                      pl={
                        user?.role &&
                        {
                          [userRole.admin]: 5,
                        }[user.role]
                      }
                    >
                      <CardHeader title={responseData.name} />
                    </Box>
                    {user?.role &&
                      {
                        [userRole.admin]: (
                          <Box p={2}>
                            <Link to={`/benchmark-groups/edit/${id}`}>
                              <EditIcon fontSize="medium" color="action" />
                            </Link>
                          </Box>
                        ),
                      }[user.role]}
                  </Box>

                  <Divider />
                  <CardContent>
                    <Box p={3}>
                      <Grid container spacing={2}>
                        {responseData.website_url ? (
                          <>
                            <Grid item xs={6}>
                              <Typography variant="h6" align="right">
                                Website URL:
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="h6" align="left">
                                <WebSiteLink
                                  onClick={() =>
                                    handleNavigateWebsite(
                                      responseData.website_url
                                    )
                                  }
                                >
                                  {responseData.website_url}
                                </WebSiteLink>
                              </Typography>
                            </Grid>
                          </>
                        ) : null}
                        {responseData.created_at ? (
                          <>
                            <Grid item xs={6}>
                              <Typography variant="h6" align="right">
                                Date created:
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="h6" align="left">
                                {dayjs(responseData.created_at).format(
                                  "DD/MM/YYYY"
                                )}
                              </Typography>
                            </Grid>
                          </>
                        ) : null}
                        {responseData.organization ? (
                          <>
                            <Grid item xs={6}>
                              <Typography variant="h6" align="right">
                                Organization:
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="h6" align="left">
                                <FetchComponent
                                  id={responseData.organization}
                                  path="organization"
                                />
                              </Typography>
                            </Grid>
                          </>
                        ) : null}
                        {responseData.description ? (
                          <>
                            <Grid item xs={6}>
                              <Typography variant="h6" align="right">
                                Description:
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="h6" align="left">
                                {responseData.description}
                              </Typography>
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                    </Box>
                    <Box display="flex" justifyContent="end">
                      {!responseData.is_active ? (
                        <Card elevation={3}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            gap={1}
                            p={2}
                          >
                            <Typography variant="body1" color="error">
                              Deactivated benchmark group{" "}
                            </Typography>
                            <Box sx={{ position: "relative" }}>
                              <Typography
                                sx={{
                                  position: "absolute",
                                  right: 5,
                                  bottom: -2,
                                  transform: "rotate(-30deg)",
                                }}
                                variant="h4"
                                color="error"
                              >
                                \
                              </Typography>
                              <Box sx={{ color: "#d32f2f" }} pr={2}>
                                <BenchmarkGroupsIcon />
                              </Box>
                            </Box>
                          </Box>
                        </Card>
                      ) : null}
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={10} pt={3} rowSpacing={{ xs: 1, md: 10 }}>
              <SpeciesDiversityCard />
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
}

export default BenchmarkGroupProfilePage;
