import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell/TableCell";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import TableBody from "@mui/material/TableBody/TableBody";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, TableSortLabel } from "@mui/material";
import {
  BenchmarkGroupDataResponseType,
  ErrorTypes,
  FarmsInBGDataType,
  Order,
  PaginationWithSort,
} from "../../types/DataTypes";
import { REACT_APP_API_URL } from "../../constants/apiConstants";
import useFetch from "../../service/useFetch";
import { useAuth } from "../../context/AuthContext";
import { TABLE_HEADERS_FARMS } from "../../constants/tableConstants";
import TableCellWithDiv from "../../components/tableCell/TableCellWithDiv";
import "../../styles/tableCell.css";
import { requestHeaderToken } from "../../utils/requestHeaderToken";
import BackComponent from "../../components/back/BackComponent";
import { PathParams } from "../../types/PropsTypes";
import AddFarmToBGModal from "../../components/modal/AddFarmToBGModal";
import useMutate from "../../service/useMutate";
import { notify } from "../../utils/notify";
import areAllPropertiesEmpty from "../../utils/isObjectEmpty";
import { userRole } from "../../constants/userRoleConstants";
import NotFoundPage from "../pageNotFound/NotFoundPage";
import { OrderEnum } from "../../constants/enums";
import PaginationComponent from "../../components/pagination/PaginationComponent";

function FarmsInBGListPage() {
  const { id: idBG } = useParams<PathParams>();
  const [refresh, setRefresh] = useState(Math.random());
  const [farmId, setFarmId] = useState<string>("");
  const { token, user } = useAuth();
  const [sort, setSort] = useState<string>("");
  const [order, setOrder] = useState<Order>(OrderEnum.ASC);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const navigate = useNavigate();

  const {
    responseData: benchmarkGroup,
    loading: loadingBenchmark,
    error: errorBenchmark,
  } = useFetch<BenchmarkGroupDataResponseType>({
    url: `${REACT_APP_API_URL}/benchmark-groups/${idBG}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  const { responseData, loading, error } = useFetch<
    PaginationWithSort<FarmsInBGDataType>
  >({
    url: `${REACT_APP_API_URL}/benchmark-groups/${idBG}/farms?page=${page}&limit=${rowsPerPage}&sort_by=${sort}&order=${order}`,
    method: "GET",
    headers: requestHeaderToken(token),
    enabled: Boolean(benchmarkGroup),
    refresh,
  });
  const { fetchData } = useMutate({
    url: `${REACT_APP_API_URL}/farms/${farmId}/benchmark-groups/${idBG}/remove`,
    method: "PUT",
    headers: requestHeaderToken(token),
  });
  const { is_default: isDefaultBG } = benchmarkGroup || {};
  const refreshRecordingSiteList = () => {
    setRefresh(Math.random());
  };
  const handleNavigateFarms = () => {
    navigate(`/benchmark-groups/${idBG}/farms/add-farms`);
  };

  const handleNavigateToForm = (id: string) => {
    navigate(`/farms/${id}`, { state: { page: "0" } });
  };
  const handleSort = (sortName: string) => {
    setSort(sortName);
    setOrder(order === OrderEnum.ASC ? OrderEnum.DESC : OrderEnum.ASC);
  };

  useEffect(() => {
    if (farmId) {
      fetchData({})
        .then(() => {
          refreshRecordingSiteList();
          notify("Farm successfully deleted!");
        })
        .catch((error: ErrorTypes) => {
          notify("Request failed!", error);
        });
    }
  }, [farmId, fetchData]);

  const deleteFarm = (id: string) => {
    setFarmId(id);
  };
  if (user?.role === userRole.organization_admin && isDefaultBG) {
    return <NotFoundPage />;
  }
  return (
    <Container>
      <div key={refresh}>
        <BackComponent />
        {(error || errorBenchmark) && (
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography variant="h4" color="error">
              Something went wrong!
            </Typography>
          </Box>
        )}
        <Grid container spacing={0} direction="row" margin={0}>
          {!isDefaultBG && (
            <Grid
              item
              pb={2}
              xs={12}
              md={12}
              display="flex"
              justifyContent="end"
            >
              {user?.role &&
                {
                  [userRole.admin]: (
                    <Button
                      sx={{ width: "50%" }}
                      type="button"
                      size="medium"
                      variant="contained"
                      onClick={handleNavigateFarms}
                    >
                      Add/Remove farms
                    </Button>
                  ),
                }[user.role]}
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={12}
            alignItems="center"
            justifyContent="center"
          >
            <TableContainer component={Paper}>
              <Box sx={{ overflow: "auto" }}>
                <Box
                  sx={{ width: "100%", display: "table", tableLayout: "fixed" }}
                >
                  <Table aria-label="table">
                    <TableHead>
                      <TableRow>
                        {TABLE_HEADERS_FARMS.map(({ sortName, label }) => (
                          <TableCell
                            className="tableCell"
                            key={label}
                            align="center"
                            onClick={() => handleSort(sortName)}
                          >
                            {label}
                            {sortName && (
                              <TableSortLabel
                                active={sort === sortName}
                                direction={sort === sortName ? order : "asc"}
                              />
                            )}
                          </TableCell>
                        ))}

                        {!isDefaultBG && (
                          <TableCell align="center">Delete</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(loading || loadingBenchmark) && !responseData && (
                        <TableRow>
                          <TableCell colSpan={10} align="center">
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      )}
                      {!loading &&
                        (!responseData ||
                          areAllPropertiesEmpty(responseData)) && (
                          <TableRow>
                            <TableCell align="center" colSpan={6}>
                              There are currently no farms in this benchmark
                              group.
                            </TableCell>
                          </TableRow>
                        )}
                      {responseData &&
                        (responseData?.results || []).map(
                          (data: FarmsInBGDataType) => {
                            return (
                              <TableRow
                                hover
                                onClick={() => handleNavigateToForm(data.id)}
                                sx={{
                                  cursor: "pointer",
                                  "&.MuiTableRow-root:hover": {
                                    backgroundColor: "rgba(206, 133, 20, 0.12)",
                                  },
                                }}
                                key={data.id}
                              >
                                <TableCellWithDiv content={data.name} />
                                <TableCellWithDiv content={data.address} />
                                <TableCellWithDiv content={data.county} />
                                <TableCellWithDiv content={data.post_code} />
                                <TableCellWithDiv content={data.CPH_number} />
                                {!isDefaultBG && (
                                  <TableCell>
                                    <IconButton
                                      onClick={() => deleteFarm(data.id)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          }
                        )}
                    </TableBody>
                    <PaginationComponent
                      count={responseData?.metadata.count}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      setPage={setPage}
                      setRowsPerPage={setRowsPerPage}
                    />
                  </Table>
                </Box>
              </Box>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default FarmsInBGListPage;
