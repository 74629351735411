import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useParams } from "react-router";
import AdditionalSpeciesData from "./AdditionalSpeciesData";
import SpeciesDiversityChart from "./SpeciesDiversityChart";
import useFetch from "../../../service/useFetch";
import { SpeciesChartFarm } from "../../../types/DataTypes";
import { REACT_APP_API_URL } from "../../../constants/apiConstants";
import { requestHeaderToken } from "../../../utils/requestHeaderToken";
import { useAuth } from "../../../context/AuthContext";
import { PathParams } from "../../../types/PropsTypes";
import YearsDropdown from "../../yearDropdown/YearsDropdown";

export default function SpeciesDiversityCard() {
  const { id } = useParams<PathParams>();
  const { token, user } = useAuth();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showAll, setShowAll] = useState<boolean>(true);

  const { responseData: data, error } = useFetch<SpeciesChartFarm[]>({
    url: `${REACT_APP_API_URL}/benchmark-groups/${id}/species-diversity?year=${selectedYear}&showAll=${showAll}`,
    method: "GET",
    headers: requestHeaderToken(token),
  });
  const handleShowAllChange = () => {
    setShowAll(!showAll);
  };
  const content = () => {
    if (error) {
      return <div>Something went wrong! </div>;
    }
    if (data && data.length !== 0) {
      return (
        <>
          {user?.role === "ORGANIZATION_ADMIN" && <AdditionalSpeciesData />}
          <SpeciesDiversityChart farms={data} />
        </>
      );
    }
    return <div>No data for that benchmark group</div>;
  };
  return (
    <Card elevation={3}>
      <Box sx={{ textAlign: "center", padding: 2.5 }}>
        <Box
          display="flex"
          gap={2}
          flexDirection={{ md: "row", sm: "column" }}
          alignItems="center"
          justifyContent="center"
        >
          <CardHeader title="Species diversity" />
          <YearsDropdown
            onChange={setSelectedYear}
            selectedYear={selectedYear}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="check"
                name="show_all"
                onChange={handleShowAllChange}
                checked={!showAll}
              />
            }
            label="Hide farms with no data"
          />
        </Box>
        <CardContent>{content()}</CardContent>
      </Box>
    </Card>
  );
}
